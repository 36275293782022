<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="6" sm="6">
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.farmer_name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">
                           {{ $i18n.locale === 'bn' ? farmerList.filter(document => document.username === farmer.farmer_id)[0] ? farmerList.filter(document => document.username === farmer.farmer_id)[0].name_bn: '' : farmerList.filter(document => document.username === farmer.farmer_id)[0] ? farmerList.filter(document => document.username === farmer.farmer_id)[0].name: '' }}
                           </p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.father_name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                        {{ $i18n.locale === 'bn' ? farmerList.filter(document => document.username === farmer.farmer_id)[0] ? farmerList.filter(document => document.username === farmer.farmer_id)[0].father_name_bn: '' : farmerList.filter(document => document.username === farmer.farmer_id)[0] ? farmerList.filter(document => document.username === farmer.farmer_id)[0].father_name: '' }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('globalTrans.division') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ getDataById(farmer.division_id, 'division') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('globalTrans.region') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{  getDataById(farmer.region_id, 'region') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('globalTrans.district') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{  getDataById(farmer.district_id, 'district') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('globalTrans.upazila') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{  getDataById(farmer.upazilla_id, 'upazila') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('globalTrans.union') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{  getDataById(farmer.union_id, 'union') }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col lg="6" sm="6">
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.warehouse_name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ this.$i18n.locale === 'bn' ? farmer.warehouse_name_bn : farmer.warehouse_name }}</p>
                      </b-col>
                    </b-row>
                     <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service_performance.complain') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark"> <span v-html="farmer.complain"></span></p>
                      </b-col>
                    </b-row>
                   <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service_performance.complain_bn') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark"> <span v-html="farmer.complain_bn"></span> </p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { complainShowApi } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFarmerDetails()
      Object.freeze(tmp)
      this.farmer_request = tmp
    }
  },
  data () {
    return {
        farmer: '',
        farmerList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload || this.loading) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    async getFarmerDetails () {
      const result = await RestApi.getData(warehouseServiceBaseUrl, `${complainShowApi}/${this.$props.id}`)
      if (result.success) {
        this.farmer = result.data
      }
      RestApi.getData(authServiceBaseUrl, '/user/farmer-details').then(response => {
        if (response.success) {
          this.farmerList = response.data
            }
        })
      this.loader = false
    },
    getDataById (Id, type) {
        if (Id) {
            var data = null
            switch (type) {
                case 'division':
                  data = this.$store.state.commonObj.divisionList.find(document => document.value === Id)
                  break
                case 'district':
                  data = this.$store.state.commonObj.districtList.find(document => document.value === Id)
                  break
                case 'upazila':
                  data = this.$store.state.commonObj.upazilaList.find(document => document.value === Id)
                  break
                case 'union':
                  data = this.$store.state.commonObj.unionList.find(document => document.value === Id)
                  break
                case 'region':
                  data = this.$store.state.warehouse.warehouseInfoList.find(document => document.value === Id)
                  break
                case 'warehouse':
                  data = this.$store.state.commonObj.divisionList.find(document => document.value === Id)
                  break
                case 'cropType':
                  data = this.$store.state.warehouse.commodityGroupList.find(document => document.value === Id)
                  break
                case 'cropName':
                  data = this.$store.state.warehouse.commodityNameList.find(document => document.value === Id)
                  break
                default:
                  break
            }
            if (data) {
                return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
            } else {
                return null
            }
        } else {
            return null
        }
    }

  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
