<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loader">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                      <b-row>
                      <b-col lg="6" sm="12">
                      <ValidationProvider name="Division" vid='division_id' rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="division"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                        readonly
                          plain
                          v-model="complainData.division_id"
                          :options="divisionList"
                          id="division_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                    <ValidationProvider name="Region" vid='region_id' rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="division"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('warehouse_config.region')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                        readonly
                          plain
                          v-model="complainData.region_id"
                          :options="regionLists"
                          id="region_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                      </b-col>
                      </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                      <ValidationProvider name="District" vid='district_id' rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="district"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          readonly
                            plain
                            v-model="complainData.district_id"
                            :options="districtList"
                            id="district_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                      <ValidationProvider name="Upazila" vid='upazilla_id' rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="upazila_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          readonly
                            plain
                            v-model="complainData.upazilla_id"
                            :options="upazilaList"
                            id="upazila_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                      <ValidationProvider name="Union" vid='union_id' rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="union_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          readonly
                            plain
                            v-model="complainData.union_id"
                            :options="unionList"
                            id="union_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                      <ValidationProvider name="Warehouse Name" vid="warehouse_id" rules="required|min_value:1">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="warehouse_id"
                            :label="$t('warehouse_information.warehouse_name')"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('warehouse_information.warehouse_name')}} <span class="text-danger">*</span>
                              </template>
                            <b-form-select
                            readonly
                              plain
                              v-model="complainData.warehouse_id"
                              :options="warehouseNameList"
                              id="warehouse_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Farmer Name" vid="farmer_id" rules="required|min_value:1">
                          <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="farmer_id"
                          :label="$t('warehouse_information.warehouse_name')"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('warehouse_service_performance.farmer_name')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          readonly
                            plain
                            v-model="complainData.farmer_id"
                            :options="currentLocale === 'en' ? farmerList : farmerListBn"
                            id="warehouse_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                    <ValidationProvider name="Remarks" rules="">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('warehouse_service_performance.complain')"
                        label-for="remarks"
                      >
                        <p class="text-dark" readonly> <span v-html="complainData.complain"></span></p>
                      </b-form-group>
                    </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                    <b-col lg="6" sm="12">
                    <ValidationProvider name="Date" vid="notice_date">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('warehouse_service_performance.complain_date')"
                        label-for="notice_date"
                        slot-scope="{ valid, errors }"
                      >
                        <b-form-input
                        readonly
                          id="establishment_date"
                          type="date"
                          v-model="complainData.complain_date"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Resolve Date" vid="resolve_date" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="resolve_date"
                      slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                         {{$t('warehouse_service_performance.resolve_date')}} <span class="text-danger">*</span>
                      </template>
                      <flat-pickr class="form-control"
                        id="resolve_date"
                        v-model="complainData.resolve_date"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></flat-pickr>
                      <!-- <b-form-input
                        id="establishment_date"
                        type="date"
                        v-model="complainData.resolve_date"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input> -->
                      <div class="invalid-feedback d-block" :show="errors.length">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                    </b-col>
                    </b-row>
                    <b-row>
                    <b-col lg="6" sm="12">
                    <ValidationProvider name="Note" vid="note" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="note"
                      slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                         {{$t('warehouse_service_performance.note')}} <span class="text-danger">*</span>
                      </template>
                    <b-form-textarea
                      id="address"
                      v-model="complainData.note"
                      placeholder="Enter Note"
                      rows="1"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-textarea>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                    <ValidationProvider name="Note (Bn)" vid="note_bn" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="note_bn"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                         {{$t('warehouse_service_performance.note_bn')}} <span class="text-danger">*</span>
                      </template>
                    <b-form-textarea
                      id="address"
                      v-model="complainData.note_bn"
                      placeholder="Enter Note (BN)"
                      rows="1"
                      max-rows="2"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-textarea>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                    </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
              </ValidationObserver>
                </b-col>
            </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '../../../../../config/api_config'
import { complainStoreApi, complainUpdateApi, complainShowApi } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  async created () {
    if (this.id) {
      await this.setFarmerDetails()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loader: true,
      saveBtnName: this.id ? this.$t('warehouse_service_performance.resolve') : this.$t('globalTrans.save'),
      complainData: {
        division_id: 0,
        region_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        warehouse_id: 0,
        farmer_id: '',
        complain: '',
        complain_bn: '',
        complain_date: '',
        resolve_date: '',
        note: '',
        note_bn: ''
      },
      warehouseNameList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      farmerList: [],
      farmerListBn: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    regionLists: function () {
      return this.$store.state.warehouse.regionList
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
    'complainData.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'complainData.region_id': function (newVal, oldVal) {
      this.regionDistrictLists = this.getRegionDistrictList(newVal)
    },
    'complainData.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'complainData.upazilla_id': function (newVal, oldVal) {
      this.warehouseNameList = this.getWarehouseName(newVal)
      this.unionList = this.getUnionList(newVal)
    },
    'complainData.union_id': function (newVal, oldVal) {
      this.warehouseNameList = this.getWarehouseName(newVal)
    },
    'complainData.warehouse_id': function (newVal, oldVal) {
      this.getFarmerList(newVal)
    }
  },
  methods: {
    async register () {
      this.loader = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: true }
      if (this.complainData.id) {
        result = await RestApi.postData(warehouseServiceBaseUrl, `${complainUpdateApi}/${this.id}`, this.complainData)
      } else {
        result = await RestApi.postData(warehouseServiceBaseUrl, complainStoreApi, this.complainData)
      }
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.complainData.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.loader = false
        this.$bvModal.hide('modal-5')
      } else {
        this.loader = false
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
      this.loader = true
    },
    async setFarmerDetails () {
      this.loader = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const result = await RestApi.getData(warehouseServiceBaseUrl, `${complainShowApi}/${this.$props.id}`)
      if (result.success) {
        this.complainData = result.data
        // this.$store.dispatch('mutateCommonProperties', { loading: false })
      } else {
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      }
      this.loader = false
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
     getRegionDistrictList (regionId = null) {
       this.loader = true
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
            if (response) {
                const dataObject = response.filter(document => document.region_id === regionId)
                this.regionDistrictLists = dataObject.map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.district_id, text: this.districtListBn[obj.district_id] }
                    } else {
                        return { value: obj.district_id, text: this.districtList[obj.district_id] }
                    }
                })
                this.loader = false
            } else {
              this.loader = false
            }
        })
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getWarehouseName (getWareid) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList
      if (this.complainData.union_id > 0) {
        return warehouseList.filter(warelist => warelist.union_id === getWareid)
      }
      if (this.complainData.upazilla_id > 0) {
        return warehouseList.filter(warelist => warelist.upazilla_id === getWareid)
      }
      return warehouseList
    },
    getFarmerList (getWareID) {
      this.loader = true
      RestApi.getData(authServiceBaseUrl, '/user/farmer-details').then(response => {
      if (response.success) {
          const dataObject = response.data.filter(document => document.warehouse_id === getWareID)
            this.farmerList = dataObject.map(obj => {
                // if (this.$i18n.locale === 'bn') {
                //     return { value: obj.username, text: this.farmerList[obj.name] }
                // } else {
                    return { value: obj.username, text: obj.name }
                // }
            })
            this.farmerListBn = dataObject.map(obj => {
                return { value: obj.username, text: obj.name_bn }
            })
            this.loader = false
        } else {
          this.loader = false
        }
      })
    }
  }
}
</script>
