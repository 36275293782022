<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
        <b-overlay  v-if="authUser.warehouse_id > 0">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6">
              <b-form-group
                class="row"
                label-cols-sm="3"
                label-for="warehouse_id"
                :label="$t('warehouse_information.warehouse_name')"
                >
                <b-form-select
                  plain
                  v-model="search.warehouse_id"
                  :options="warehouseNameList"
                  id="warehouse_id"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
          </b-row>
        </b-overlay>
        <b-overlay v-if="authUser.warehouse_id === 0 || authUser.warehouse_id == null">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('org_pro_division.division')"
              label-for="division_id"
              >
              <v-select name="division_id"
                v-model="search.division_id"
                label="text"
                :options= divisionList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
              />
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('warehouse_config.region')"
              label-for="division_id"
              >
              <v-select name="region_id"
                v-model="search.region_id"
                label="text"
                :options= regionLists
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('org_pro_district.district')"
              label-for="district_id"
              >
              <v-select name="district_id"
                v-model="search.district_id"
                label="text"
                :options= regionDistrictLists
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('org_pro_upazilla.upazilla')"
              label-for="upazilla_id"
              >
              <v-select name="upazilla_id"
                v-model="search.upazilla_id"
                label="text"
                :options= upazilaList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
              />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('org_pro_union.union')"
              label-for="union_id"
              >
             <v-select name="union_id"
                v-model="search.union_id"
                label="text"
                :options= unionList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
              />
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              label-for="warehouse_id"
              :label="$t('warehouse_information.warehouse_name')"
              >
               <v-select name="warehouse_id"
                v-model="search.warehouse_id"
                label="text"
                :options= warehouseNameList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
              </b-form-group>
            </b-col>
            <b-col>
                <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_service_performance.complain') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped bordered hover :items="complainDataList" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(division_name)="data">
                      <span class="capitalize">{{ data.item.division_name }}</span>
                    </template>
                    <template v-slot:cell(region_name)="data">
                      <span class="capitalize">{{ data.item.region_name }}</span>
                    </template>
                    <template v-slot:cell(distict_name)="data">
                      <span class="capitalize">{{ data.item.distict_name }}</span>
                    </template>
                    <template v-slot:cell(upzila_name)="data">
                      <span class="capitalize">{{ data.item.upzila_name }}</span>
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_name }}</span>
                    </template>
                    <template v-slot:cell(farmer_name)="data">
                      <span class="capitalize">{{ data.item.farmer_name }}</span>
                    </template>
                    <template v-slot:cell(farmer_land_area)="data">
                      <span class="capitalize">{{ $n(data.item.farmer_land_area) }}</span>
                    </template>
                    <template v-slot:cell(complain_date)="data">
                      <span class="capitalize">{{ data.item.complain_date|dateFormat }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      <a href="javascript:" v-b-modal.modal-7 class="btn_table_action table_action_view" title="View Complain" @click="edit(data.item)"><i class="fas fa-eye"></i></a>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button  v-if="(itemStatus(data.item) === 1)" v-b-modal.modal-5 class="btn-outline-danger" variant="iq-bg-success mr-1 mb-1" size="sm"  @click="edit(data.item)">{{ $t('warehouse_service_performance.resolve') }}</b-button>
                      <b-button v-if="(itemStatus(data.item) === 2)" class="btn-outline-success" variant="iq-bg-success mr-1 mb-1" size="sm">{{ $t('complain.solved') }}</b-button>
                    </template>
                  </b-table>
                  <b-pagination
                  align="center"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  @input="testClick"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
      <b-modal id="modal-7" size="lg" :title="$t('warehouse_service_performance.complain')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Detail :id="testId" :key="testId"/>
      </p>
      </b-modal>
      <b-modal id="modal-5" size="lg" :title="$t('warehouse_service_performance.complain')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Resolve :id="testId" :key="testId"/>
      </p>
      </b-modal>
      <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
          <FormV :id="testId" :key="testId"/>
        </p>
      </b-modal>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import FormV from './Form'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { complainListApi, complainToggleStatusApi } from '../../api/routes'
import Detail from './Details'
import Resolve from './ResolveForm'

export default {
  name: 'UiDataTable',
  components: {
    FormV, Detail, Resolve
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        warehouse_name: '',
        warehouse_id: null,
        division_id: null,
        region_id: null,
        district_id: null,
        upazilla_id: null,
        union_id: null
      },
      complainData: {
        divText: '',
        divText_bn: '',
        disText: '',
        disText_bn: '',
        upzText: '',
        upzText_bn: '',
        regionText: '',
        regionText_bn: '',
        wareHouseText: '',
        wareHouseText_bn: ''
      },
      testId: 0,
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      farmerList: [],
      complainDataList: [],
      warehouseNameList: [],
      regionDistrictLists: []
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    formTitle () {
       return this.$t('warehouse_service_performance.complain')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('warehouse_information.division'), class: 'text-center' },
          { label: this.$t('warehouse_config.region'), class: 'text-center' },
          { label: this.$t('warehouse_information.district'), class: 'text-center' },
          { label: this.$t('warehouse_information.upazilla'), class: 'text-center' },
          { label: this.$t('warehouse_config.warehouse'), class: 'text-center' },
          { label: this.$t('warehouse_service_performance.farmer_name'), class: 'text-center' },
          { label: this.$t('warehouse_service_performance.land_area'), class: 'text-center' },
          { label: this.$t('warehouse_service_performance.date'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name_bn' },
          { key: 'region_nam_bn' },
          { key: 'distict_name_bn' },
          { key: 'upzila_name_bn' },
          { key: 'wareName_bn' },
          { key: 'farmer_name_bn' },
          { key: 'farmer_land_area' },
          { key: 'complain_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'region_nam' },
          { key: 'distict_name' },
          { key: 'upzila_name' },
          { key: 'wareName' },
          { key: 'farmer_name' },
          { key: 'farmer_land_area' },
          { key: 'complain_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    warehouseTypeList: function () {
      return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    regionLists: function () {
      let regionList = this.$store.state.warehouse.regionList.filter(item => item.status === 0)
      if (this.isWareHouseUser) {
        regionList = regionList.filter(item => item.value === this.search.region_id)
      }
      return regionList
    },
    regionDetailList: function () {
      return this.$store.state.warehouse.regionDetailList
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.region_id': function (newVal, oldVal) {
      this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.warehouseNameList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseNameList = this.getWarehouseName(newVal, 'UP')
    }
  },
  created () {
    if (this.authUser.warehouse_id > 0) {
      this.getWarehouseNameSearch(this.authUser.warehouse_id)
    }
  },
  mounted () {
    core.index()
    if (this.isWareHouseUser) {
      this.search.division_id = this.authUser.office_detail.division_id
      this.search.region_id = this.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
      this.search.district_id = this.authUser.office_detail.district_id
      this.search.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
      this.search.union_id = this.authUser.office_detail.union_id ?? 0
      const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
      this.search.warehouse_id = warehouse.value
    }
    this.loadData()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        division_id: '',
        region_id: '',
        district_id: '',
        upazilla_id: '',
        union_id: '',
        warehouse_name: '',
        warehouse_name_bn: '',
        file_name: '',
        file_name_bn: '',
        total_capacity: '',
        warehouse_type_id: '',
        remarks: ''
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.testId = item.id
    },
    toggleStatus (item) {
      RestApi.deleteData(warehouseServiceBaseUrl, `${complainToggleStatusApi}/${item.id}`).then(response => {
        if (response.success) {
          this.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: true })
    },
    remove (item) {
      this.$swal({
        title: this.$t('globalTrans.statusChangeMsg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.toggleStatus(item)
        }
      })
    },
    getDistrictList (divisionId = null) {
      const regionDistrictLists = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return regionDistrictLists.filter(district => district.division_id === divisionId)
      }

      return regionDistrictLists
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)

      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }

      return unionList
    },
    getWarehouseName (unionId) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
      if (unionId) {
        return warehouseList.filter(document => document.union_id === unionId)
      }
      return warehouseList
    },
    getWarehouseNameSearch (ID) {
      const warehouseData = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
      if (ID) {
        const filterData = warehouseData.filter(item => item.value === ID)
        this.search.warehouse_id = filterData[0].value
        this.warehouseNameList = filterData
      }
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObject = response.filter(document => document.region_id === regionId)
          dataObject.map(obj => {
            const district = this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id)
            if (typeof district !== 'undefined') {
              const item = {
                value: obj.district_id,
                text: this.$i18n.locale === 'bn' ? district.text_bn : district.text_en
              }
              this.regionDistrictLists.push(item)
            }
          })
        }
      })
    },
    itemStatus (item) {
      return item.status
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      await RestApi.getData(authServiceBaseUrl, '/user/farmer-details').then(response => {
        if (response.success) {
          this.farmerList = response.data
          RestApi.getData(warehouseServiceBaseUrl, complainListApi, params).then(response => {
            if (response.success) {
              this.complainDataList = this.ListShow(response.data.data)
              this.paginationData(response.data)
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            } else {
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            }
          })
         } else {
           this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
         }
        })
    },
    ListShow (data) {
      let tmpData = {}
      let tmpData2 = {}
      let tmpUpzila = {}
      let tmpRegion = {}
      let tmpFarmerData = {}
      let tmpWarehouseData = {}
      const listData = data.map(item => {
        tmpData = this.$store.state.commonObj.divisionList.find(divItem => divItem.value === item.division_id && divItem.status === 0)
        tmpData2 = this.$store.state.commonObj.districtList.find(Disitem => Disitem.value === item.district_id && Disitem.status === 0)
        tmpUpzila = this.$store.state.commonObj.upazilaList.find(upItem => upItem.value === item.upazilla_id && upItem.status === 0)
        tmpRegion = this.$store.state.warehouse.regionList.find(regionItem => regionItem.value === item.region_id && regionItem.status === 0)
        tmpWarehouseData = this.$store.state.warehouse.warehouseInfoList.find(wareItem => wareItem.value === item.warehouse_id && wareItem.status === 0)
        if (tmpData) {
          this.complainData.divText = tmpData.text
          this.complainData.divText_bn = tmpData.text_bn
        }
        if (tmpData2) {
          this.complainData.disText = tmpData2.text
          this.complainData.disText_bn = tmpData2.text_bn
        }
        if (tmpUpzila) {
          this.complainData.upzText = tmpUpzila.text
          this.complainData.upzText_bn = tmpUpzila.text_bn
        }
        if (tmpRegion) {
          this.complainData.regionText = tmpRegion.text
          this.complainData.regionText_bn = tmpRegion.text_bn
        }
        if (tmpWarehouseData) {
          this.complainData.wareHouseText = tmpWarehouseData.text
          this.complainData.wareHouseText_bn = tmpWarehouseData.text_bn
        }
        tmpFarmerData = this.farmerList.find(farmer => farmer.mobile_no === item.farmer_id)
        const complainDataLists = { division_name: this.complainData.divText, division_name_bn: this.complainData.divText_bn, distict_name: this.complainData.disText, distict_name_bn: this.complainData.disText_bn, upzila_name: this.complainData.upzText, upzila_name_bn: this.complainData.upzText_bn, farmer_name: tmpFarmerData !== undefined ? tmpFarmerData.name : '', farmer_name_bn: tmpFarmerData !== undefined ? tmpFarmerData.name_bn : '', farmer_land_area: tmpFarmerData !== undefined ? tmpFarmerData.land_area : '', region_nam: this.complainData.regionText, region_nam_bn: this.complainData.regionText_bn, wareName: this.complainData.wareHouseText, wareName_bn: this.complainData.wareHouseText_bn }
        return Object.assign({}, item, complainDataLists)
      })
      return listData
    }
  }
}
</script>
