<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <!-- <b-overlay  v-if="authUser.warehouse_id > 0">
              <b-row>
                <b-col lg="11" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                      <ValidationProvider name="Farmer Name" vid="farmer_id" rules="required|min_value:1">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="farmer_id"
                        :label="$t('warehouse_information.warehouse_name')"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('warehouse_service_performance.farmer_name')}} <span class="text-danger">*</span>
                          </template>
                        <b-form-select
                          plain
                          v-model="complainData.farmer_id"
                          :options="farmerList"
                          id="warehouse_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :disabled="isWareHouseUser"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        </b-form-group>
                      </ValidationProvider>
                  <ValidationProvider name="Complain" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('warehouse_service_performance.complain')"
                      label-for="remarks"
                      slot-scope="{ valid, errors }"
                    >
                    <vue-editor
                    id="remarks"
                    useCustomImageHandler
                    v-model="complainData.complain"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></vue-editor>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Complain (Bn)">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('warehouse_service_performance.complain_bn')"
                      label-for="remarks_bn"
                      slot-scope="{ valid, errors }"
                    >
                    <vue-editor
                    id="remarks_bn"
                    useCustomImageHandler
                    v-model="complainData.complain_bn"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></vue-editor>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Date" rules="required">
                      <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('warehouse_service_performance.complain_date')"
                      label-for="date"
                      slot-scope="{ valid, errors }"
                      >
                      <flat-pickr class="form-control"
                        v-model="complainData.complain_date"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </flat-pickr>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                      </b-form-group>
                  </ValidationProvider>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay> -->
            <b-overlay>
              <b-row>
                <b-col lg="11" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                  <ValidationProvider name="Division" vid='division_id' rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="division"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="complainData.division_id"
                        :options="divisionList"
                        id="division_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :disabled="isWareHouseUser"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Region" vid='region_id' rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="region_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('warehouse_config.region')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="complainData.region_id"
                        :options="regionLists"
                        id="region_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :disabled="isWareHouseUser"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="District" vid='district_id' rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="district_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="complainData.district_id"
                        :options="districtList"
                        id="district_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :disabled="isWareHouseUser"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Upazila" vid='upazilla_id' rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="upazila_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="complainData.upazilla_id"
                        :options="upazilaList"
                        id="upazila_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :disabled="isWareHouseUser"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Union" vid='union_id' rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="union_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="complainData.union_id"
                        :options="unionList"
                        id="union_id"
                        :disabled="isWareHouseUser"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Warehouse Name" vid="warehouse_id" rules="required|min_value:1">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="warehouse_id"
                        :label="$t('warehouse_information.warehouse_name')"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('warehouse_information.warehouse_name')}} <span class="text-danger">*</span>
                          </template>
                        <b-form-select
                          plain
                          v-model="complainData.warehouse_id"
                          :options="warehouseNameList"
                          id="warehouse_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :disabled="isWareHouseUser"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Farmer Name" vid="farmer_id" rules="required|min_value:1">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="farmer_id"
                        :label="$t('warehouse_information.warehouse_name')"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('warehouse_service_performance.farmer_name')}} <span class="text-danger">*</span>
                          </template>
                        <b-form-select
                          plain
                          v-model="complainData.farmer_id"
                          :options="farmerList"
                          id="warehouse_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        </b-form-group>
                      </ValidationProvider>
                  <ValidationProvider name="Complain (En)" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('warehouse_service_performance.complainf')"
                      label-for="remarks"
                      slot-scope="{ valid, errors }"
                    >
                    <vue-editor
                    id="remarks"
                    useCustomImageHandler
                    v-model="complainData.complain"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></vue-editor>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Complain (Bn)">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('warehouse_service_performance.complain_bn')"
                      label-for="remarks_bn"
                      slot-scope="{ valid, errors }"
                    >
                    <vue-editor
                    id="remarks_bn"
                    useCustomImageHandler
                    v-model="complainData.complain_bn"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></vue-editor>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Date" rules="required">
                      <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('warehouse_service_performance.complain_date')"
                      label-for="date"
                      slot-scope="{ valid, errors }"
                      >
                      <flat-pickr class="form-control"
                        v-model="complainData.complain_date"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </flat-pickr>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                      </b-form-group>
                  </ValidationProvider>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '../../../../../config/api_config'
import { complainStoreApi, complainUpdateApi } from '../../api/routes'
import { VueEditor } from 'vue2-editor'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider,
    VueEditor
  },
  created () {
    if (this.id) {
      const tmp = this.getWarehouseInformationData()
      Object.freeze(tmp)
      this.complainData = tmp
    }
  },
  mounted () {
    core.index()
    if (this.isWareHouseUser) {
      this.complainData.division_id = this.authUser.office_detail.division_id
      this.complainData.region_id = this.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
      this.complainData.district_id = this.authUser.office_detail.district_id
      this.complainData.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
      this.complainData.union_id = this.authUser.office_detail.union_id ?? 0
      const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
      this.complainData.warehouse_id = warehouse.value
    }
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('warehouse_service_performance.resolve') : this.$t('globalTrans.save'),
      complainData: {
        division_id: 0,
        region_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        warehouse_id: 0,
        farmer_id: 0,
        complain: '',
        complain_bn: '',
        complain_date: ''
      },
      warehouseNameList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      farmerList: [],
      regionDistrictLists: []
    }
  },
  computed: {
    authUser () {
      const authUser = this.$store.state.Auth.authUser
      if (authUser.warehouse_id > 0) {
        const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.value === authUser.warehouse_id)
        this.dataSet(warehouseList[0])
      }
      return authUser
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    regionDetailList: function () {
      return this.$store.state.warehouse.regionDetailList
    },
    regionLists: function () {
      return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    isDisabled () {
      return true
    }
  },
  watch: {
    'complainData.division_id': function (newVal, oldVal) {
      this.regionDistrictLists = this.getDistrictList(newVal)
    },
    'complainData.region_id': function (newVal, oldVal) {
      this.regionDistrictLists = this.getRegionDistrictList(newVal)
    },
    'complainData.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'complainData.upazilla_id': function (newVal, oldVal) {
      this.warehouseNameList = this.getWarehouseName(newVal)
      this.unionList = this.getUnionList(newVal)
    },
    'complainData.union_id': function (newVal, oldVal) {
      this.warehouseNameList = this.getWarehouseName(newVal)
    },
    'complainData.warehouse_id': function (newVal, oldVal) {
      this.getFarmerList(newVal)
    }
  },
  methods: {
    getWarehouseInformationData () {
      return this.$store.state.WareHouseConfig.warehouseInformations.find(item => item.id === this.id && item.status === 0)
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }
      if (this.complainData.id) {
        result = await RestApi.postData(warehouseServiceBaseUrl, `${complainUpdateApi}/${this.id}`, this.complainData)
      } else {
        result = await RestApi.postData(warehouseServiceBaseUrl, complainStoreApi, this.complainData)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.complainData.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getDistrictList (divisionId = null) {
      const regionDistrictLists = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return regionDistrictLists.filter(district => district.division_id === divisionId)
      }
      return regionDistrictLists
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObject = response.filter(document => document.region_id === regionId)
          dataObject.map(obj => {
            const district = this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id)
            if (typeof district !== 'undefined') {
              const item = {
                value: obj.district_id,
                text: this.$i18n.locale === 'bn' ? district.text_bn : district.text_en
              }
              this.districtList.push(item)
            }
          })
        }
      })
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getWarehouseName (getWareid) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
      if (this.complainData.union_id > 0) {
        return warehouseList.filter(warelist => warelist.union_id === getWareid)
      }
      if (this.complainData.upazilla_id > 0) {
        return warehouseList.filter(warelist => warelist.upazilla_id === getWareid)
      }
      return warehouseList
    },
    getFarmerList (getWareID) {
      RestApi.getData(authServiceBaseUrl, '/user/farmer-details').then(response => {
      if (response.success) {
          const dataObject = response.data.filter(document => document.warehouse_id === getWareID)
            this.farmerList = dataObject.map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.username, text: obj.name_bn }
                } else {
                    return { value: obj.username, text: obj.name }
                }
            })
        }
      })
    },
     dataSet (warehouseData) {
      this.complainData.division_id = warehouseData.division_id
      this.complainData.district_id = warehouseData.district_id
      this.complainData.upazilla_id = warehouseData.upazilla_id
      this.complainData.union_id = warehouseData.union_id
      this.complainData.warehouse_id = warehouseData.value
      this.complainData.region_id = warehouseData.region_id
      RestApi.getData(authServiceBaseUrl, '/user/farmer-list').then(response => {
      if (response) {
          const dataObject = response.data.filter(item => item.warehouse_id === warehouseData.value)
          this.warehouseFarmerList = dataObject.map(obj => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.username, text: obj.name_bn }
              } else {
                  return { value: obj.username, text: obj.name }
              }
          })
        }
      })
      return true
    }
  }
}
</script>
